import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { catchError, tap } from 'rxjs/operators';
import { GlobalService } from './global.service';
import { environment } from '../../environments/environment';
import { LocalCacheService } from './localCache.service';

@Injectable({
  providedIn: 'root',
})
export class PDSService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "apiKey": environment.DMSAPI.apiKey
    }),
  };
  lastestPDS: any;
  currentLatestTMD: any;
  constructor(
    private http: HttpClient, 
    private globalService: GlobalService,
    private localCacheService: LocalCacheService
  ) {
    this.getTMD();
    this.http
      .get<any>(
        `${environment.DMSAPI.baseUrl}api/pds/latest?club=${environment.autoClub}`,
        this.httpOptions,
      )
      .subscribe({
        next: (response) => {
          this.lastestPDS = response.pds[0];
        },
        error: (error) => {
          console.error('Error fetching latest PDS', error);
        },
      });
  }

  getAllPDS() {
    this.globalService.showLoading('getAllPDS');
    return this.http
      .get<any>(
        `${environment.DMSAPI.baseUrl}api/pds?club=${environment.autoClub}`,
        this.httpOptions,
      )
      .pipe(
        tap((_) => {
          this.globalService.hideLoading('getAllPDS');
          console.log('fetched all pds');
        }),
        catchError(this.globalService.handleError<any>('all pds', [])),
      );
  }

  getLatestPDS() {
    this.globalService.showLoading('getLatestPDS');
    return this.http
      .get<any>(
        `${environment.DMSAPI.baseUrl}api/pds/latest?club=${environment.autoClub}`,
        this.httpOptions,
      )
      .pipe(
        tap((_) => {
          this.globalService.hideLoading('getLatestPDS');
          console.log('fetched latest pds');
        }),
        catchError(this.globalService.handleError<any>('latest pds', [])),
      );
  }

  downloadPDSPDF(url: string, fileName: string): void {
    const newWindow = window.open(url, '_blank');
    if (newWindow) {
      newWindow.opener = null;
    } else {
      // Handle the case where the new tab could not be opened
      alert(
        'Unable to open PDF in a new tab. Please check your browser settings. Make sure Block Pop-ups is turned off or/and Make sure JavaScript is turned on',
      );
    }
  }

  downloadLatestPDSPDF(): void {
    if (!this.lastestPDS) {
      alert('No PDS available');
      return;
    }
    const newWindow = window.open(this.lastestPDS.url, '_blank');
    if (newWindow) {
      newWindow.opener = null;
    } else {
      // Handle the case where the new tab could not be opened
      alert(
        'Unable to open PDF in a new tab. Please check your browser settings. Make sure Block Pop-ups is turned off or/and Make sure JavaScript is turned on',
      );
    }
  }

  getTMD() {
    this.currentLatestTMD = this.localCacheService.getSessionStorage('lastestTMD');
    if(!this.currentLatestTMD) {
      this.http
      .get<any>(
        `${environment.DMSAPI.baseUrl}api/tmd?club=${environment.autoClub}`,
        this.httpOptions,
      )
      .subscribe({
        next: (response) => {
          if (response && response.tmd && response.tmd.length > 0) {
            response.tmd.forEach((obj: any) => {
              if(obj.isActive) {
                this.localCacheService.saveSessionStorage('lastestTMD', obj);
              }
            });
          }
        },
        error: (error) => {
          console.error('Error fetching latest TMD', error);
        },
      });
    }
  }

  getLatestTMD() {
    this.globalService.showLoading('getLatestTMD');
    return this.http
      .get<any>(
        `${environment.DMSAPI.baseUrl}api/tmd?club=${environment.autoClub}`,
        this.httpOptions,
      )
      .pipe(
        tap((_) => {
          this.globalService.hideLoading('getLatestTMD');
          console.log('fetched latest tmd');
        }),
        catchError(this.globalService.handleError<any>('latest tmd', [])),
      );
  }
}
